import React from 'react'
import './support.css'
import Footer from './Footer';
import Navbar from './NavBar';
import LynkdLogo from './assets/lynkdLogo.jpeg'
export default function Support() {
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    return (
        <div className="page-wrapper">
            <Navbar />
            <main className="main-content">
                <div className="contact-container">
                    <div className="contact-form-section">
                        <span className="get-in-touch">Get in Touch</span>
                        <h1>Let's Chat</h1>
                        <p className="description">
                            Have questions or feedback? We're here to help. Send us a message, and we'll respond within 24 hours
                        </p>

                        <form className="contact-form" name="contact" method="POST" data-netlify="true">
                            <input type="hidden" name="form-name" value="contact" />
                            <div className="name-row">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder="First name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder="Last name"
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email address"
                                />
                            </div>

                            <div className="form-group">
                                <label>Message</label>
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Leave us message"
                                    rows="6"
                                ></textarea>
                            </div>

                            <div className="privacy-policy">
                                <input type="checkbox" id="privacy" required />
                                <label htmlFor="privacy">
                                    I agree to our friendly <a href="/policies/privacy-policy">privacy policy</a>.
                                </label>
                            </div>
                            <button type="submit" className="submit-button">
                                Send Message
                            </button>
                        </form>
                    </div>

                    <div className="contact-info-section">
                        <div className="image-container">
                            <img src={LynkdLogo} alt="Contact support" />
                        </div>

                        <div className="contact-details">
                            <div className="contact-item">
                                <div className="icon email-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z" fill="currentColor" />
                                    </svg>
                                </div>
                                <div className="contact-text">
                                    <h3>Support Email</h3>
                                    <p><a href="mailto:support@lynkd.app">support@lynkd.app</a></p>
                                </div>
                            </div>
                            <div className="contact-item">
                                <div className="icon email-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6ZM20 6L12 11L4 6H20ZM20 18H4V8L12 13L20 8V18Z" fill="currentColor" />
                                    </svg>
                                </div>
                                <div className="contact-text">
                                    <h3>Technical Support</h3>
                                    <p><a href="mailto:tech@lynkd.app">tech@lynkd.app</a></p>
                                </div>
                            </div>

                            {/* <div className="contact-item">
                                <div className="icon phone-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.62 10.79C8.06 13.62 10.38 15.94 13.21 17.38L15.41 15.18C15.69 14.9 16.08 14.82 16.43 14.93C17.55 15.3 18.75 15.5 20 15.5C20.55 15.5 21 15.95 21 16.5V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z" fill="currentColor" />
                                    </svg>
                                </div>
                                <div className="contact-text">
                                    <h3>Phone</h3>
                                    <p>(0252) 8324 9231</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

