import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import './GetStarted.css'
import SubmitResponse from "../SubmitResponse";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { IoIosImage, IoIosClose } from "react-icons/io";
import InstaProfile from "../InstaProfile";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { app } from "../firebase";
import { sendEmailVerification, signInAnonymously, signInWithEmailAndPassword, updateEmail, getAuth, createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { FcNext } from "react-icons/fc";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FloatingLabelInputStyled from "../FloatingLabelInputStyled";
import FloatingLabelInputStyledEmail from "../FloatingLabelInputStyledEmail";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Navbar from "../NavBar";
import Footer from "../Footer";
import PhoneNumberInput from "../components/inputs/PhoneNumberInput";
import OTPInput from "../components/inputs/OTPInput";

// Validation Schema for form
const validationSchema = [
    Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
    }),
    Yup.object({
        phoneNumber: Yup.string().required("Required"),
    }),
    Yup.object({
        otp: Yup.string().required("Required"),
    }),
    Yup.object({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
    }),
    Yup.object({
        photoURL: Yup.string().required("Profile photo is required"),
    }),
    Yup.object({
        dob: Yup.date().required("Required"),
    }),
    Yup.object({
        gender: Yup.string().oneOf(["Male", "Female"]).required("Required"),
    }),
    Yup.object({
        username: Yup.string()
            // .matches(/^[a-zA-Z0-9]+$/, "Invalid username")
            .required("Required"),
    }),
    Yup.object({
        instagram: Yup.string().required("Required"),
    }),
    // Yup.object({
    //     password: Yup.string().min(8, "Password must be at least 8 characters").required("Required"),
    //     confirmPassword: Yup.string()
    //         .oneOf([Yup.ref("password"), null], "Passwords must match")
    //         .required("Required"),
    // }),
];

const GetStarted = (props) => {
    const [step, setStep] = useState(0); // Manage which step we are on
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [serverMessage, setServerMessage] = useState('');
    const [direction, setDirection] = useState("next");  // Control animation direction
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phoneVerified, setPhoneVerified] = useState(false);
    const totalSteps = 8;
    // Initialize the Formik hook
    const [instagramData, setInstagramData] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    // ignore the below error if it shows up
    //     Cannot read properties of null (reading 'style')
    // TypeError: Cannot read properties of null (reading 'style')
    //     at Array.<anonymous> (https://www.gstatic.com/recaptcha/releases/pPK749sccDmVW_9DSeTMVvh2/recaptcha__en.js:43:62)
    //     at yx.<anonymous> (https://www.gstatic.com/recaptcha/releases/pPK749sccDmVW_9DSeTMVvh2/recaptcha__en.js:382:496)
    // code to ignore the above errorr
    window.onerror = function (message, source, lineno, colno, error) {
        if (message === "Cannot read properties of null (reading 'style')") {
            return true;
        }
    };

    // useEffect(() => {
    //     // Fetch and convert the image into a Blob
    //     const fetchImage = async () => {
    //         try {
    //             console.log("iamge", imageSrc)
    //             const response = await fetch(imageSrc);
    //             const blob = await response.blob();
    //             const localUrl = URL.createObjectURL(blob);
    //             console.log("localUrl", localUrl)
    //             // Set the blob URL to the state to display it in the image tag
    //             setImageSrc(localUrl);

    //             // Clean up by revoking the object URL after a few seconds
    //             setTimeout(() => {
    //                 URL.revokeObjectURL(localUrl);  // This removes the local image
    //                 setImageSrc(null); // Clear the state after removal
    //             }, 5000); // Delete the image after 5 seconds
    //         } catch (error) {
    //             console.error('Error fetching the image:', error);
    //         }
    //     };

    //     fetchImage();
    // }, [instagramData]);

    const fetchImageBlob = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    }


    const fetchInstagram = async (username) => {
        try {
            // const response = await fetch(`http://192.168.1.70:5000/get_profile_more_details?username=${username}`);
            const config = {
                url: `${process.env.REACT_APP_INSTAURL}/${username}`,
                // url: `http://192.168.1.70:5000/get_profile_more_details?username=${username}`,
                method: 'get',
                redirect: 'follow',
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_INSTAKEY
                }
            }

            const url = `${process.env.REACT_APP_INSTAURL}/download/${username}_profile_pic.jpg`;
            await axios.request(config).then(
                async response => {
                    // const data = response.data.data
                    // const values = {
                    //     follower_count: data.user.edge_followed_by.count,
                    //     following_count: data.user.edge_follow.count,
                    //     full_name: data.user.full_name,
                    //     post_count: data.user.edge_owner_to_timeline_media.count,
                    //     profile_pic_url: url,
                    //     username: data.user.username
                    // }
                    // setInstagramData(values);
                    // // console.log("prpURL", values.profile_pic_url)
                    // setImageSrc(values.profile_pic_url);
                    // console.log(response.data)
                    console.log(response.data)
                    const values = {
                        follower_count: response.data.data.followers,
                        following_count: response.data.data.following,
                        full_name: response.data.data.fullName,
                        post_count: response.data.data.postsCount,
                        profile_pic_url: response.data.data.profilePic,
                        username: response.data.data.username
                    }
                    setInstagramData(values);
                    setImageSrc(values.profile_pic_url);
                }
            );
            // return response.data;


        } catch (error) {
            console.error("Error fetching Instagram data:", error);
            return null;
        }
    }


    const formik = useFormik({
        initialValues: {
            email: "",
            otp: "",
            firstName: "",
            lastName: "",
            photoURL: "",
            dob: "",
            gender: "",
            username: "",
            instagram: "",
            phoneNumber: "",
            // password: "",
            // confirmPassword: "",
        },
        validationSchema: validationSchema[step],
        onSubmit: async (values) => {
            if (isSubmitting) return; // Prevent double submissions

            setIsSubmitting(true);
            // let photoURL = null;
            // const values2 = new URLSearchParams({
            //     email: "a@gmail.com",
            //     otp: "121",
            //     firstName: "1221",
            //     lastName: "121",
            //     dob: "1212",
            //     gender: "1221",
            //     username: "2112",
            //     instagram: "2121",
            // }).toString();
            // if (values.photo) {
            //     photoURL = await convertToBase64(values.photo);
            // }

            // const payload = {
            //     ...values,
            //     photoURL
            // };
            // console.log(values2)
            // console.log(values)
            const values2 = new URLSearchParams({
                ...values,
                phoneNumber: formik.values.phoneNumber.replace('+', ''),
            }).toString();
            try {
                // Simulated submission - replace with real server call
                const config = {
                    url: `${process.env.REACT_APP_GOOGLE_SHEETS_URL}?${values2}`,
                    method: 'get',
                    redirect: 'follow',
                    headers: {
                        'Content-Type': 'text/plain;charset=utf-8',
                    }
                }
                const response = await axios.request(config);
                // On success
                console.log(response.status)
                setSubmissionStatus('success');
                setServerMessage('We will notify once your account is verified! Meanwhile let your followers know about it.');
            } catch (error) {
                setSubmissionStatus('error');
                setServerMessage('There was an issue submitting the form. Please try again.');
            }
        },
    });

    // Converts file to base64
    // const convertToBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = (error) => reject(error);
    //     });
    // };

    // Function to move to the next step
    const nextStep = () => {
        if (formik.isValid) {
            setDirection("next");  // Set animation direction
            setStep(step + 1);
        }
    };

    // Function to move to the previous step
    const previousStep = () => {
        setDirection("prev");  // Set animation direction

        setStep(step - 1);
    };

    // console.log(formik.values)


    const storage = getStorage(app);
    const [isUploading, setIsUploading] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const handleImageChange = async (event) => {
        const file = event.currentTarget.files[0];
        setIsUploading(true); // Start loading indicator
        setProfilePhoto(URL.createObjectURL(file));
        // Options for image compression
        const options = {
            maxSizeMB: 0.5,        // Maximum size in MB
            maxWidthOrHeight: 720, // Maximum width/height
            useWebWorker: true,  // Use multi-threading for compression
        };

        try {
            // Compress the image
            const compressedFile = await imageCompression(file, options);

            // Create a storage reference in Firebase
            const storageRef = ref(storage, `profile_images/${formik.values.phoneNumber}`);

            // Upload the compressed image to Firebase
            const snapshot = await uploadBytes(storageRef, compressedFile);

            // Get the download URL of the uploaded image
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Set the Firebase image URL in the Formik field
            formik.setFieldValue("photoURL", downloadURL);

        } catch (error) {
            console.error("Error uploading image: ", error);
        } finally {
            setIsUploading(false); // Stop loading indicator
        }
    };

    const Spinner = () => {
        return <div className="spinner"></div>;
    };

    const [timeLeft, setTimeLeft] = useState(120); // 2-minute timer
    const [verificationSent, setVerificationSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [stopNext, setStopNext] = useState(false);
    // Timer countdown function
    useEffect(() => {
        if (step === 1 && timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft, step]);
    const auth = getAuth(app);
    // console.log(formik.isValid)
    // check which formik field is invalid
    // console.log(formik.errors)

    // Function to create an anonymous user and send verification email
    const sendVerificationEmailFunc = async (email) => {
        try {
            const password = Math.random().toString(36).substring(7) + '@password.com';
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(userCredential.user);
            setVerificationSent(true);
            setStopNext(true);
            setStep(step + 1);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
            if (error == "FirebaseError: Firebase: Error (auth/email-already-in-use).") {
                sendEmailOnly(email);
                setStep(step + 1);
            }
        }
    };

    const sendEmailOnly = async (email) => {
        try {
            await sendEmailVerification(auth.currentUser);
            setVerificationSent(true);
            // setStep(step + 1);
            setStopNext(true);
            startTimer();
        } catch (error) {
            console.error('Error sending verification email: ', error);
        }
    }

    const setTimer = (time) => {
        setTimeLeft(time);
    };

    // Start the 2-minute timer for email verification
    const startTimer = () => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(interval); // Stop the timer at 0
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // Decrement every second
    };

    // Check email verification
    const checkVerification = async () => {
        const user = auth.currentUser;
        await user.reload(); // Reload the user to get the updated emailVerified status
        if (user.emailVerified) {
            setEmailVerified(true)
            setStopNext(false);
        } else {
            alert('Email not verified yet. Please check your inbox.');
        }
    };


    const initializeRecaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        console.log("Recaptcha solved", response);
                    },
                    "expired-callback": () => {
                        console.log("Recaptcha expired. Please try again.");
                    },
                },
            );
        }
    };
    useEffect(() => {
        // Call this function before invoking `createAccountWithPhoneNumber`
        initializeRecaptcha(auth);
    }, []);
    // send firebase otp
    const createAccountWithPhoneNumber = async (phoneNumber) => {
        try {
            const appVerifier = window.recaptchaVerifier;
            const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
            window.confirmationResult = confirmationResult;
            console.log("OTP sent successfully");
            setStopNext(true);
            setStep(step + 1);
            startTimer();
        } catch (error) {
            console.error("Error sending OTP: ", error);
        }
    };

    const verifyOTP = async () => {
        try {
            const code = formik.values.otp;
            const confirmationResult = window.confirmationResult;
            await confirmationResult.confirm(code);
            console.log("OTP verified successfully");
            setPhoneVerified(true);
            setStopNext(false);
        } catch (error) {
            console.error("Error verifying OTP: ", error);
        }
    };

    const handleOTPChange = (otp) => {
        formik.setFieldValue("otp", otp);
    };

    useEffect(() => {
        if (formik.values.otp.length === 6) {
            verifyOTP();
        }
    }, [formik.values.otp]);

    // if enter is pressed on phone number input
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            createAccountWithPhoneNumber(formik.values.phoneNumber);
        }
    }

    // Function to render each form question
    const renderStep = () => {
        switch (step) {
            // case 0:
            //     return (
            //         <>
            //             <div style={{ flexDirection: 'row', display: 'flex' }}>
            //                 {/* <label>Email ID:</label> */}
            //                 <FloatingLabelInputStyledEmail
            //                     type="email"
            //                     name="email"
            //                     placeholderText="Email ID"
            //                     onChange={(e) => formik.setFieldValue("email", e.target.value)}
            //                     value={formik.values.email}
            //                     onClick={() => { sendVerificationEmailFunc(formik.values.email) }}
            //                 />
            //                 {formik.errors.email && formik.touched.email && <div>{formik.errors.email}</div>}
            //             </div>
            //             {/* <button type="button" onClick={() => { sendVerificationEmailFunc(formik.values.email) }}>Send Verification Email</button> */}
            //         </>
            //     );
            // case 1:
            //     return (
            //         <div style={{ textAlign: 'left', height: 70 }}>
            //             {!emailVerified && timeLeft != 0 && <>
            //                 {/* <label style={{ marginBottom: 10 }}>Waiting for email verification... </label> */}
            //                 <div>Time left: {timeLeft} seconds</div>
            //                 <button className="getStartedBtn" type="button" onClick={checkVerification} style={{ width: '100%', margin: 0, fontFamily: 'Montserrat, sans-serif' }}>
            //                     Check Verification
            //                 </button>
            //             </>
            //             }
            //             {emailVerified && (
            //                 <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'green' }}>
            //                     Email verified! Please proceed to the next step.
            //                 </div>
            //             )}
            //             {timeLeft === 0 && (
            //                 <>
            //                     <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'red', marginBottom: 10 }}>
            //                         Time limit exceeded! Please try again.
            //                     </div>
            //                     <button className="getStartedBtn" type="button" onClick={() => { sendEmailOnly(formik.values.email) }} style={{ margin: 0 }}>Send Verification Email</button>
            //                 </>
            //             )}
            //         </div>
            //     );
            case 0:
                return (
                    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto" }} onKeyDown={handleEnter}
                    >
                        <PhoneNumberInput
                            placeholderText="Phone Number"
                            onChange={(e) => formik.setFieldValue("phoneNumber", e.target.value)}
                            onEnter={() => { createAccountWithPhoneNumber(formik.values.phoneNumber) }}
                        />
                        <button type="button" className="getStartedBtn" onClick={() => { createAccountWithPhoneNumber(formik.values.phoneNumber) }}>Send Verification OTP</button>
                    </div>
                );
            case 1:
                return (
                    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", }}>
                        {!phoneVerified && timeLeft != 0 && <>
                            {/* <label style={{ marginBottom: 10 }}>Waiting for email verification... </label> */}
                            <div>Time left: {timeLeft} seconds</div>
                        </>
                        }
                        {phoneVerified && (
                            <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'green' }}>
                                Phone verified! Please proceed to the next step.
                            </div>
                        )}
                        {timeLeft === 0 && (
                            <>
                                <div style={{ fontSize: "1.2em", textAlign: 'center', display: 'block', margin: 'auto', color: 'red', marginBottom: 10 }}>
                                    Time limit exceeded! Please try again.
                                </div>
                                <button className="getStartedBtn" type="button" onClick={() => { setStep(0) }} style={{ margin: 0 }}>Verify Phone Number Again</button>
                            </>
                        )}
                        <OTPInput numBoxes={6} onChange={handleOTPChange} />
                        <div style={{ height: 20 }} />
                        {!phoneVerified && timeLeft != 0 && <>
                            <button className="getStartedBtn" type="button" onClick={verifyOTP} style={{ width: '100%', margin: 0, fontFamily: 'Montserrat, sans-serif' }}>
                                Check Verification
                            </button>
                        </>
                        }
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>First Name:</label> */}
                            {/* <input
                                type="text"
                                name="firstName"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                            /> */}
                            <FloatingLabelInputStyled
                                placeholderText="First Name"
                                value={formik.values.firstName}
                                onChange={(e) => formik.setFieldValue("firstName", e.target.value)}
                                name="firstName"
                                type="text"
                            />
                            {formik.errors.firstName && formik.touched.firstName && <div>{formik.errors.firstName}</div>}
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Last Name:</label> */}
                            <FloatingLabelInputStyled
                                type="text"
                                name="lastName"
                                placeholderText="Last Name"
                                onChange={(e) => formik.setFieldValue("lastName", e.target.value)}
                                value={formik.values.lastName}
                            />
                            {formik.errors.lastName && formik.touched.lastName && <div>{formik.errors.lastName}</div>}
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div style={{ position: 'relative', width: 120, height: 120, display: 'block', margin: 'auto', marginBottom: 40 }}>
                        {/* Profile Placeholder Image */}
                        <img
                            src={profilePhoto ? profilePhoto : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'}
                            alt="Profile"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                border: '2px solid #ccc'
                            }}
                        />

                        {/* Pencil Icon for Edit */}
                        <label htmlFor="photo-upload">
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    backgroundColor: '#1b1b1b',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    width: 25,
                                    height: 25,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer'
                                }}
                            >
                                <IoIosImage size={25} color="#fff" />
                            </div>
                        </label>
                        <label style={{ display: 'flex', width: 200, textAlign: 'center', alignSelf: 'center', }}>Upload Photo</label>

                        {/* Hidden File Input */}
                        <input
                            id="photo-upload"
                            type="file"
                            name="photo"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                        />

                        {/* Error Message */}
                        {formik.errors.photo && formik.touched.photo && <div>{formik.errors.photo}</div>}
                    </div>

                );
            case 4:
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <label>Date of Birth:</label>
                        <input
                            type="date"
                            name="dob"
                            onChange={formik.handleChange} // Use formik.handleChange
                            value={formik.values.dob}
                            style={{ textTransform: 'uppercase', textAlign: 'center', width: 200 }}
                        />
                        {formik.errors.dob && formik.touched.dob && <div>{formik.errors.dob}</div>}
                    </div>
                );
            case 5:
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <label>Gender:</label>
                        {/* <select
                            name="gender"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                        >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select> */}
                        {/* gender radio buttons */}
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <label style={{ margin: 2 }}>
                                Male
                            </label>
                            <input
                                type="radio"
                                name="gender"
                                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                                value="Male"
                                checked={formik.values.gender === "Male"}
                            />
                            <label style={{ marginLeft: 30 }}>
                                Female
                            </label>
                            <input
                                type="radio"
                                name="gender"
                                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
                                value="Female"
                                checked={formik.values.gender === "Female"}
                            />
                        </div>

                        {formik.errors.gender && formik.touched.gender && <div>{formik.errors.gender}</div>}
                    </div>
                );
            case 6:
                return (
                    <div style={{}}>
                        {/* <label>Username:</label> */}
                        <FloatingLabelInputStyledEmail
                            type="email"
                            name="email"
                            placeholderText="Email ID"
                            onChange={(e) => formik.setFieldValue("email", e.target.value)}
                            value={formik.values.email}
                        />
                        <FloatingLabelInputStyled
                            type="text"
                            name="username"
                            placeholderText="Username"
                            onChange={(e) => formik.setFieldValue("username", e.target.value)}
                            value={formik.values.username}
                        />
                        {formik.errors.username && formik.touched.username && <div>{formik.errors.username}</div>}
                    </div>
                );
            case 7:
                return (
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {/* <label>Instagram Username:</label> */}
                            <FloatingLabelInputStyled
                                type="text"
                                name="instagram"
                                placeholderText="Instagram Username"
                                onChange={(e) => formik.setFieldValue("instagram", e.target.value)}
                                value={formik.values.instagram}
                            />
                            {formik.errors.instagram && formik.touched.instagram && <div>{formik.errors.instagram}</div>}
                        </div>
                        <div style={{ height: 20 }} />
                        <button className="getStartedBtn" type="button" onClick={() => fetchInstagram(formik.values.instagram)} >Verify Instagram ID</button>
                        {instagramData && (
                            <InstaProfile profileData={instagramData} />
                        )}
                    </>
                );
            // case 8:
            //     setStep(step + 1);
            //     return (
            //         // <div>
            //         //     <label>Password:</label>
            //         //     <input
            //         //         type="password"
            //         //         name="password"
            //         //         onChange={formik.handleChange}
            //         //         value={formik.values.password}
            //         //     />
            //         //     {formik.errors.password && formik.touched.password && <div>{formik.errors.password}</div>}

            //         //     <label>Confirm Password:</label>
            //         //     <input
            //         //         type="password"
            //         //         name="confirmPassword"
            //         //         onChange={formik.handleChange}
            //         //         value={formik.values.confirmPassword}
            //         //     />
            //         //     {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            //         //         <div>{formik.errors.confirmPassword}</div>
            //         //     )}
            //         // </div>
            //     );
            default:
                return (
                    <div>
                        <p style={{ width: '100%' }}>You have reached the end of the form.</p>
                        <p style={{ width: '100%', fontSize: 14 }}>If you would like to review your responses before submitting please navigate below else press submit.</p>
                    </div>
                );
        }
    };
    // console.log(formik.isValid, formik.errors)

    // check if window.location is /get-started
    const isGetStartedPage = window.location.pathname === '/get-started';

    return (
        <>
            {isGetStartedPage && <Navbar />}
            <div
                id="recaptcha-container"
            >
            </div>
            <div className="form-container" style={isGetStartedPage ? {
                borderRadius: 0,
                elevation: 0,
                boxShadow: 'none',
            } : {}}>
                <h1 className="form-header">Become a<br></br> Creator!</h1>
                {/* Heading */}
                {/* Conditionally render either the form or submission response */}
                {submissionStatus ?
                    <SubmitResponse status={submissionStatus} message={serverMessage} />
                    : (
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                key={step} // Use step as key to trigger re-render on change
                                timeout={300} // Transition duration
                                classNames={direction === "next" ? "slide-next" : "slide-prev"} // Animation classes based on direction
                                unmountOnExit
                            >
                                <form onSubmit={formik.handleSubmit} className="form-white">
                                    <div style={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 20px', // Optional, for better spacing on smaller screens
                                        alignSelf: 'center',
                                    }}>
                                        <div style={{ maxWidth: '450px', width: '100%' }}>
                                            {renderStep()}
                                        </div>
                                    </div>


                                    <div className="button-group">
                                        {step > 0 && (
                                            <button type="button" onClick={previousStep}>
                                                <FaArrowLeft size={25} color="grey" />
                                            </button>
                                        )}
                                        {step != 0 ?
                                            step < totalSteps - 1 ? (
                                                <button
                                                    type="button"
                                                    onClick={nextStep}
                                                    disabled={!formik.isValid || stopNext}
                                                    className={`next-btn-form ${step < totalSteps ? 'slide-in' : 'slide-out'}`}
                                                >
                                                    <FaArrowRight size={25} color="black" />
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    style={{
                                                        width: 100,
                                                        height: 40,
                                                    }}
                                                    className="next-btn-form slide-in getStartedBtn"
                                                >
                                                    Submit
                                                </button>
                                            ) : null}
                                    </div>
                                </form>
                            </CSSTransition>
                        </SwitchTransition>

                    )}
                {/* Button to go back */}
                {/* <button onClick={() => { props.setShow(!props.show) }} style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 40,
                    height: 40,
                    background: '#fff',
                    color: '#000',
                    padding: '10px',
                    borderBottomLeftRadius: 25,
                    border: 'none',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}>
                    <IoIosClose size={50} />
                </button> */}
            </div>
            {isGetStartedPage && <Footer />}
        </>
    );
};

export default GetStarted;