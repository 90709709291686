import React, { useEffect } from 'react';
import './InstaProfile.css';

const InstaProfile = ({ profileData }) => {
    const { follower_count, following_count, full_name, post_count, profile_pic_url, username } = profileData;
    // console.log(profileData)
    // Format follower and post counts to have commas
    const formatCount = (num) => {
        return num;
    };

    // console.log(profile_pic_url)

    const [profilePicBlob, setProfilePicBlob] = React.useState(null);


    useEffect(() => {
        const fetchImage = async () => {
            try {
                // curl request:
                // curl -X POST -H "Content-Type: application/json" -d '{"url":"<profile_pic_url>"}' ${process.env.REACT_APP_INSTAURL}/image/get
                const response = await fetch(`${process.env.REACT_APP_INSTAURL}/image/get`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_INSTAKEY}`
                    },
                    body: JSON.stringify({ url: profile_pic_url })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const blob = await response.blob();
                setProfilePicBlob(blob);
            } catch (error) {
                console.error('Error fetching the image:', error);
            }
        };
        fetchImage();
    }, [profile_pic_url]);

    return (
        <div className="profile-container">
            <div className="profile-header">
                <div>
                    {profilePicBlob && <img src={URL.createObjectURL(profilePicBlob)} alt={`${username}'s profile`} className="profile-pic" />}
                    <div className='profile-info '>
                        <h2 className="full-name">{full_name}</h2>
                        <h3 className="username">@{username}</h3>
                    </div>
                </div>
                <div className="profile-stats">
                    <div className="stat-item">
                        <div className="stat-label">Posts</div>
                        <div className="stat-number">{formatCount(post_count)}</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-label">Followers</div>
                        <div className="stat-number">{formatCount(follower_count)}</div>
                    </div>
                    <div className="stat-item">
                        <div className="stat-label">Following</div>
                        <div className="stat-number">{formatCount(following_count)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default InstaProfile;
